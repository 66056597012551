/* footer page */
.footerStyle:hover {
  color: #0fe50f;
}

.footerStyle {
  text-decoration: none;
  color: rgb(55, 54, 54);
  font-size: 10pt;
}

/* Activities page */
.hover {
  overflow: hidden;
  position: relative;
  padding-bottom: 60%;
}

.hover-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 90;
  transition: all 0.4s;
}

.hover img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s;
}

.hover-content {
  position: relative;
  z-index: 99;
}

.hover-3::after {
  content: '';
  width: calc(100% - 3rem);
  height: calc(100% - 3rem);
  border: 1px solid #fff;
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  z-index: 90;
  transition: all 0.3s;
  transform: scale(1.1);
  opacity: 0;
  display: block;
  opacity: 0;
}

.hover-3-content {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
  text-align: center;
  z-index: 99;
}

.hover-3-description {
  opacity: 0;
  transform: scale(1.3);
  transition: all 0.3s;

}

.hover-3 img {
  width: 110%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hover-3 .hover-overlay {
  background: rgba(0, 0, 0, 0.2);
}

.hover-3:hover img {
  width: 100%;
}

.hover-3:hover::after {
  opacity: 1;
  transform: none;
}

.hover-3:hover .hover-3-description {
  opacity: 1;
  transform: none;
}

.hover-3:hover .hover-overlay {
  background: rgba(0, 0, 0, 0.8);
}

.activityFont {
  font-size: 4pt;
}

  @media (min-width: 576px) {
    .activityFont {
      font-size: 6pt;
    }
  }

  @media (min-width: 768px) {
    .activityFont {
      font-size: 8pt;
    }
  }

  /* home page */
  .cover {
    object-fit: cover;
    width: 1200px;
    height: 400px
  }

  @media (min-width: 768px) {
    .cover {
      object-fit: cover;
      height: 740px;
    }
  }

  .carousel-caption {
    color: white;
    background-color: rgba(17, 1, 1, 0.226);
  }

  .greenText {
    color: green;
  }

  /* cuisine page */
  .woodImageHeight {
    height: 30rem;
  }

  @media (min-width: 768px) {
    .woodImageHeight {
      height: 20rem;
    }
  }

  /* others */
  .noUnderline {
    text-decoration: none;
  }

  .noUnderline:hover {
    text-decoration: underline;
  }

  /* Admin Login */
  /* .loginForm {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% ,-50%);
    width: 400px;
  } */